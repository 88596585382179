
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.img-mini {
	max-width: 50px;
}

.img-gravatar {
	float: left;
    position: relative;
    margin-top: -5px;
    margin-right: 5px;
    border-radius: 50%;
}